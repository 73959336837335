/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.tabs {
  width: 100%;
  height: 38px;
  background: #eeeeee;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
}
.tabs .btn {
  width: 100px;
  height: 100%;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}
.tabs .active {
  background: #ffffff;
}
