/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.c-authList {
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 32px;
  margin: 4px 0;
}
